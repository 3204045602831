//  src/pages/Sponsors.js

import React, { useState } from 'react';
import '../styles/pages/sponsors.css';
import { sponsorsData } from '../data/sponsorsData';
import PeopleList from '../components/PeopleList';

const SponsorsAndPartners = () => {
    const [selectedRole, setSelectedRole] = useState('All');

    const filterSponsors = (role) => {
        return sponsorsData.filter((sponsor) =>
            role === 'All' ? true : sponsor.role.includes(role)
        );
    };

    return (
        <div className="sponsors-section">
            <h2 className="sponsors-title">Sponsors and Partners</h2>
            <div className="chips-container">
                <button
                    className={`chip ${selectedRole === 'All' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('All')}
                >
                    All
                </button>
                <button
                    className={`chip ${selectedRole === 'Sponsors' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('Sponsors')}
                >
                    Sponsors
                </button>
                <button
                    className={`chip ${selectedRole === 'Partners' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('Partners')}
                >
                    Partners
                </button>
            </div>
            <PeopleList data={filterSponsors(selectedRole)} />
        </div>
    );
};

export default SponsorsAndPartners;
