// src/data/expertsData.js


import paulThomas from '../assets/images/paulThomas.jpg';
import paulBBC from '../assets/images/paulBBC.jpg';
import AndyPincott from '../assets/images/AndyPincott.jpg';
import melissaTruffle from '../assets/images/melissaTruffle.jpg';
import JesperLaunder from '../assets/images/JesperLaunder.jpg';
import SJ from '../assets/images/sj.png';

export const expertsData = [
    {
        name: 'Prof. Paul Thomas',
        role: 'Speakers',
        bio: `Prof. Paul Thomas, a global authority on truffles, holds dual roles as the Managing Director of Mycorrhizal Systems Ltd (MSL) and Chief Scientist of the American Truffle Company® (ATC). 
        With an academic foundation in Natural Environmental Science from the University of Sheffield, Prof. Thomas has spent his career dedicated to the exploration and cultivation of truffles.
        He co-founded ATC with Robert Chang in 2007, revolutionising truffle cultivation with innovative scientific methodologies. 
        At MSL, Prof. Thomas has honed advanced inoculation techniques and strategic orchard management strategies, thereby reducing the typical risks in truffle farming.
        His seminal research, initiated in 2001, has fostered a broad global truffle research network under MSL, substantially expanding our comprehension of truffle biology. His contributions were recognised by BBC's Dragon's Den program.
        Prof. Thomas's tireless dedication culminated in the first scientifically cultivated Black Perigord truffle harvest in the UK in March 2015, a significant accomplishment for MSL and ATC. 
        He extended his vast knowledge to the general public as the keynote speaker at last year's Great British Truffle Festival. 
        His engaging presentations on truffle cultivation and the intriguing topic of "Truffle Sex, Insects, and DNA" were warmly received by the audience, solidifying his reputation as a compelling communicator. 
        Prof. Thomas also frequently shares his expertise through TV, radio, and various publications.`,
        description: 'Sustainable truffle cultivation in the UK',
        images: [
            paulThomas,
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: JesperLaunder,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
    {
        name: 'Andy Pincott',
        role: 'Speakers',
        bio: `Andy planted 3 acres of truffle trees back in 2009 as an investment for the future and part of a wider plan to do something different after corporate life! 
        Having nurtured the plot for many years, whilst continuing to fulfil a day job in a suit. 
        Andy has now joined the ‘gig economy’ and moved full time into jobs that nurture his passions. 
        In addition to truffle farming, he runs the Instagram account @affordablewinehunter, builds outdoor bars and kitchens and completes art commissions.
        In this talk Andy will provide an honest account of what it takes to set up a truffle plantation – the highs and the lows – and will provide numerous tips to anyone thinking about this journey, or who has already started down this path.`,
        description: '“From root to plate”: The trials and tribulations of establishing a truffle orchard',
        images: [
            AndyPincott,
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: JesperLaunder,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
    {
        name: 'Melissa Waddingham',
        role: 'Speakers',
        bio: `A professional truffle hunter and wild mushroom picker. Melissa has been in the industry for 16 years. 
        In the spring and autumn months running mushroom forays, truffle hunts, talks, courses and throughout the year providing truffle hound training days for your truffle hounds to be at beginner, intermediate or experienced level.
        Melissa founded the Great British Truffle Festival in 2022-2023 and has re-launched the festival under a new name and in partnership with Michael Corcoran. 
        The Great British Truffle Festival.
        Melissa offers truffle hound Zoom courses which have been a great addition to her one-to-one or group classes.
        Melissa also manages existing woodlands for the sustainable production of truffles, carries out woodland surveys to establish their presence and records fungi species for landowners unaware of what they have on their land.
        Melissa is also a passionate cook and especially enjoys creating interesting and flavoursome food for clients on her forays or for private events and festivals, recipes always including wild fungi and truffles.`,
        description: 'Sustainable hunting, duty of care and the law',
        images: [
            melissaTruffle
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: JesperLaunder,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
    {
        name: 'Jesper Launder',
        role: 'Workshops',
        bio: `Jesper developed a fascination for fungi as a child after a couple of memorable fungi-related events; 
        a magical mushroom foray with close family, skipping through the woods collecting mushrooms for the basket (the intention had been for the adults to identify them using a nature ID book, but they sadly ended up in the bin, unidentified).
        Then spotting, and collecting a mushroom seen on the way home from school, knowing it matched perfectly one of the species on a mushroom poster on the fridge at home. 
        The perfect Shaggy Ink Cap was then eaten with his family, the first time Jesper had enjoyed eating a mushroom! 
        What followed was a rapid descent into the mushroom-shaped wormhole of fungi obsession. 
        As an adult Jesper trained as a medical herbalist, developing a particular interest in the medicinal mushrooms. 
        Edible mushrooms had always been a strong interest and Jesper has been sharing knowledge and experience on fungi ID courses for the last 25 years. 
        In more recent years, following a handful of random truffle finds on forays, and independently, he has been honing the skills of finding truffles without a dog, primarily by looking for evidence of truffle excavation by foraging animals. 
        Unearthing one of the many possible native species is both thrilling and fascinating. It's very much like a lottery; you simply don't know what your find is until it's uncovered, smelt, or in some cases, until it is under the microscope.`,
        description: 'Microscopes, Mycology and Truffles',
        images: [
            JesperLaunder
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: JesperLaunder,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
    {
        name: 'Sarah J Ebdon',
        role: 'Workshops',
        bio: `S-J will be working alongside Jesper with the microscope workshop. ⁠S-J is an artist, educator and lifelong mycophile whose fascination just gets bigger as she learns more about what she doesn’t know. She first encountered fungi as a child when introduced to the joy of foraging mushrooms from the garden by her Mum and has explored a deepening connection to the local landscape through the fungi that live there ever since.⁠
⁠In recent years diving into the realm of microscopy has opened up new layers of wonder and excitement. She sees the awe-inspiring architecture of nature as a place where art and science collide.⁠⁠
S-J is a regular contributor to Bucks Fungus Group (http://www.bucksfungusgroup.org.uk/) and their outreach work. She loves to explore ways of learning and noticing and seeing awe sparked in others. Believing that when we connect in a sensorial way to the world around us that’s when we become truly alive. ⁠
`,
        description: 'Microscopes, Mycology and Truffles',
        images: [
            SJ
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: JesperLaunder,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: JesperLaunder,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
];
