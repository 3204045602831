// src/pages/Experts.js

import React, { useState } from 'react';
import '../styles/pages/experts.css';
import { expertsData } from '../data/expertsData';
import PeopleList from '../components/PeopleList';

const Experts = () => {
    const [selectedRole, setSelectedRole] = useState('All');

    const filterExperts = (role) => {
        return expertsData.filter((expert) =>
            role === 'All' ? true : expert.role.includes(role)
        );
    };

    return (
        <div className="experts-section">
            <div className='content-wrapper'>
                <h2 className="experts-title">Meet the Experts</h2>
                <div className="description-experts">
                    <p>At The Great British Truffle Festival, we celebrate the rich intersection of natural science, gastronomy, dogs and sustainability.</p>
                    <p>Throughout the day, you'll have the opportunity to engage with leading experts in the field who will share their knowledge through captivating talks, interactive workshops, and live demonstrations. From the science of truffle cultivation to the artistry of cooking with these prized fungi, our experts will guide you through a fascinating journey that combines tradition with modern ecological practices.</p>
                    <p>The festival schedule is packed with diverse activities, ensuring there's something for everyone - whether you're here to learn, taste, or train your truffle-hunting dog.</p>
                </div>
            </div>
            
            <div className="chips-container">
                <button
                    className={`chip ${selectedRole === 'All' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('All')}
                >
                    All
                </button>
                <button
                    className={`chip ${selectedRole === 'Speakers' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('Speakers')}
                >
                    Speakers
                </button>
                <button
                    className={`chip ${selectedRole === 'Workshops' ? 'selected' : ''}`}
                    onClick={() => setSelectedRole('Workshops')}
                >
                    Workshops
                </button>
            </div>
            <PeopleList data={filterExperts(selectedRole)} />
        </div>
    );
};

export default Experts;
