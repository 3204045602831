import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import '../styles/theme.css';
import '../styles/pages/finefoods.css';

import HeroSection from '../sections/HeroSection';
import { CartContext } from '../context/CartContext';
import TastingMenu from '../components/TastingMenu';
import VegTastingMenu from '../components/VegTastingMenu';
import ALCMenu from '../components/ALCMenu';
import LogoScroller from '../components/LogoScroller';

import chefImage1 from '../assets/images/chris_cutting.png';  
import drinksPartnerImage from '../assets/images/bucknbirch.jpg'; 
import demoMan from '../assets/images/truffle_demo.webp'; 

import logo1 from '../assets/images/chrisCV/delaunay.png';
import logo2 from '../assets/images/chrisCV/masterchef.png';
import logo3 from '../assets/images/chrisCV/lepontdelatour.png';
import logo4 from '../assets/images/chrisCV/pass.png';
import logo5 from '../assets/images/chrisCV/wolseley.png';
import logo6 from '../assets/images/chrisCV/edition.png';
import logo7 from '../assets/images/chrisCV/delaunay.png';
import logo8 from '../assets/images/chrisCV/masterchef.png';
import logo9 from '../assets/images/chrisCV/lepontdelatour.png';
import logo10 from '../assets/images/chrisCV/pass.png';
import logo11 from '../assets/images/chrisCV/wolseley.png';
import logo12 from '../assets/images/chrisCV/edition.png';

const FineFoods = () => {
    const [products, setProducts] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('TastingMenu');
    const { cartItems, addItemToCart, increaseQuantity, decreaseQuantity } = useContext(CartContext);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const getCartItemQuantity = (productId) => {
        const cartItem = cartItems.find(item => item.id === productId);
        return cartItem ? cartItem.quantity : 0;
    };

    const handleAddToCart = (product) => {
        addItemToCart(product);
    };

    return (
        <div>
            <HeroSection
                title="Fine Foods Experience"
                description="Discover the exquisite taste of truffles with our fine dining and à la carte menus."
                isHalfHeight={true}
            />
            <br />
            <div className="content-wrapper">
                <p>
                At The Great British Truffle Festival, our love for truffles and the environment in which they thrive is at the heart of everything we do. Our Fine Foods experiences are an extension of this passion, offering you a culinary journey that celebrates the unique flavours of truffles in a variety of different shapes and flavours, sustainably sourced from the rich, natural landscapes in which we dine and meet together. Each dish is crafted with the same respect and care that we hold for our truffle ecosystems, bringing together the finest ingredients with a sense of earthy innovation. 
                </p>
            </div>
            <br />

            <section className="chef-section py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={chefImage1} alt="Chef Christian Cutting" className="d-block w-100 img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Meet Our Chef</h2>
                            <p>Christian's culinary roots trace back to his childhood in Brighton, where he spent his early years foraging for prawns and crabs along the shores of Brighton's Black Rock. His passion for food led him to France after leaving school, where he trained as a chef de cuisine. Immersed in the rich culinary traditions of the South of France, Christian began his journey into the world of Wild Food, working with some of the finest ingredients in a setting that deeply influenced his approach to cooking.</p>
                            <p>In 2012, Christian relocated to London, where he came to work in some of the city's most prestigious kitchens, including The Wolseley, Delaunay, Skylon, Le Pont de la Tour, and Edition Hotel. His career also took him to The South Lodge Hotel in Sussex, where he worked at the Michelin-starred restaurant, The Pass. During this time, Christian had the opportunity to work alongside renowned chefs such as Jason Atherton, Ian Swainson, and Matt Gillan, and in 2019, he showcased his talents on MasterChef: The Professionals, reaching the Quarter Finals.</p>
                            <p>Christian's culinary journey eventually led him back to Brighton, where he reconnected with his passion for Wild Food, Foraging, and Truffles. Teaming up with his brother Sebastian, they launched Urban Forage in 2021, offering a unique Wild Food dining experience. The Amy brothers now host Wild Food workshops, cooking demonstrations, fine dining events, and foraged banquets in Stanmer Park and across Sussex. Christian continues to innovate with a focus on Truffle-themed experiences, incorporating fire, fungi, game, and, of course, the finest Sussex truffles.</p>
                        </div>
                    </div>
                </div>
                <LogoScroller images={[logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12]} /> 
            </section>

            <section className="menu-section">
                <div className="menu-container">
                    <h2 className="text-center">Our Menus</h2>

                    {/* Chip Category Selector */}
                    <div className="menu-category-selector text-center mb-4">
                        <button
                            className={`chip ${selectedMenu === 'TastingMenu' ? 'selected' : ''}`}
                            onClick={() => setSelectedMenu('TastingMenu')}
                        >
                            9 Course Tasting Menu
                        </button>
                        <button
                            className={`chip ${selectedMenu === 'VegTastingMenu' ? 'selected' : ''}`}
                            onClick={() => setSelectedMenu('VegTastingMenu')}
                        >
                            9 Course Vegetarian Tasting Menu
                        </button>
                        <button
                            className={`chip ${selectedMenu === 'ALCMenu' ? 'selected' : ''}`}
                            onClick={() => setSelectedMenu('ALCMenu')}
                        >
                            Truffle Trail À La Carte
                        </button>
                    </div>

                    <div className="menu-row">
                        {selectedMenu === 'TastingMenu' && (
                            <TastingMenu
                                products={products}
                                getCartItemQuantity={getCartItemQuantity}
                                handleAddToCart={handleAddToCart}
                                increaseQuantity={increaseQuantity}
                                decreaseQuantity={decreaseQuantity}
                            />
                        )}
                        {selectedMenu === 'VegTastingMenu' && (
                            <VegTastingMenu
                                products={products}
                                getCartItemQuantity={getCartItemQuantity}
                                handleAddToCart={handleAddToCart}
                                increaseQuantity={increaseQuantity}
                                decreaseQuantity={decreaseQuantity}
                            />
                        )}
                        {selectedMenu === 'ALCMenu' && (
                            <ALCMenu
                                products={products}
                                getCartItemQuantity={getCartItemQuantity}
                                handleAddToCart={handleAddToCart}
                                increaseQuantity={increaseQuantity}
                                decreaseQuantity={decreaseQuantity}
                            />
                        )}
                    </div>
                    <p className="text-center">We cater to various dietary requirements. Please inform us of any specific needs.</p>
                </div>
                <div className="text-center mt-4 cart-button">
                    <Link to="/cart" className="btn btn-secondary">Go to Cart</Link>
                </div>
            </section>

            <section className="drinks-partner-section py-5">
                <div className="container text-center">
                    <h2>Our Wild Spirits Partner</h2>
                    <img src={drinksPartnerImage} alt="Luxury Drinks Partner" className="img-fluid rounded mb-4" />
                    <p>Enjoy exquisite spirits from our luxury partner, perfectly paired with our gourmet meals.</p>
                    <a href="https://buckandbirch.com/" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Visit Our Drinks Partner</a>
                </div>
            </section>

            <section className="cooking-demos-section py-5 bg-light">
                <div className="container text-center">
                    <h2>Cooking Demos</h2>
                    <p>Learn how to cook with truffles from our expert chefs throughout the day at our cooking demo stands. Watch live demonstrations and take away recipes to try at home.</p>
                    <img src={demoMan} alt="Luxury Drinks Partner" className="img-fluid rounded mb-4" />
                </div>
            </section>
        </div>
    );
};

export default FineFoods;
