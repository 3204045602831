// src/components/PeopleList.js

import React from 'react';
import '../styles/components/peopleList.css';
import LinksSlider from './LinksSlider';

const PeopleList = ({ data }) => {
    return (
        <div className="people-list-section">
            {data.map((person, index) => (
                <div
                    key={index}
                    className={`person-container ${index % 2 === 0 ? 'row-reverse' : ''}`}
                >
                    <div className="person-images">
                        {person.images.map((image, idx) => (
                            <img key={idx} src={image} alt={`${person.name} ${idx + 1}`} />
                        ))}
                    </div>
                    <div className="person-info">
                        <h3 className="person-name">{person.name}</h3>
                        <div className="person-role">{person.role}</div>
                        {person.description && (
                            <p className="person-description"><strong>Description: </strong>{person.description}</p>
                        )}
                        <p className="person-bio">{person.bio}</p>
                        {person.links && <LinksSlider links={person.links} />}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PeopleList;
