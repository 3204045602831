//src/context/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentSession, getCurrentAccount, loginAccount, logoutAccount } from '../lib/Appwrite';

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState('guest');
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            console.log('Fetching current user... (from AuthContext)');
            try {
                const currentSession = await getCurrentSession();
                if (currentSession) {
                    try {
                        const currentUser = await getCurrentAccount();
                        console.log('User fetched successfully:', currentUser);
                        setUser(currentUser);
                        const userType = currentUser.labels && currentUser.labels[0] ? currentUser.labels[0] : 'guest';
                        console.log('User type:', userType);
                        setUserType(userType);

                        try {
                            const userPurchases = await fetchUserPurchases(currentUser.$id);
                            setPurchases(userPurchases);
                        } catch (error) {
                            console.error('Failed to fetch user purchases:', error);
                            setPurchases([]);
                        }
                    } catch (error) {
                        console.error('Failed to fetch user data:', error);
                        setUser(null);
                        setUserType('guest');
                        setPurchases([]);
                    }
                } else {
                    throw new Error('No active session found');
                }
            } catch (error) {
                console.error('No active session');
                setUser(null);
                setUserType('guest');
                setPurchases([]);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const fetchUserPurchases = async (accountId) => {
        console.log("fetchUserPurchases(", accountId, ")");
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tickets/${accountId}`);
            const purchases = response.data;
            console.log(purchases);
            return purchases;
        } catch (error) {
            console.error('Failed to fetch user purchases:', error);
            return [];
        }
    };

    const login = async (email, password) => {
        try {
            await loginAccount(email, password);
        } catch (error) {
            console.error('Failed to create email session:', error);
            throw error;
        }

        let currentUser;
        try {
            currentUser = await getCurrentAccount();
            setUser(currentUser);
        } catch (error) {
            console.error('Failed to fetch user after login (AuthContext.js):', error);
            throw error;
        }

        try {
            const userType = currentUser.labels && currentUser.labels[0] ? currentUser.labels[0] : 'guest';
            setUserType(userType);
        } catch (error) {
            console.error('Failed to determine user type:', error);
            throw error;
        }

        try {
            const userPurchases = await fetchUserPurchases(currentUser.$id);
            setPurchases(userPurchases);
        } catch (error) {
            console.error('Failed to fetch user purchases:', error);
            throw error;
        }
    };

    const signup = async (email, password, name, userType = 'guest', tickets = []) => {
        const userId = uuidv4().replace(/-/g, '');
        try {
            console.log('Creating user account...');
            await axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
                userId,
                email,
                password,
                name,
                userType
            });
            console.log('User account created successfully.');
        } catch (error) {
            console.error('Failed to create user account:', error);
            throw error;
        }

        try {
            await login(email, password);
        } catch (error) {
            console.error('Failed to login after signup:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await logoutAccount();
            setUser(null);
            setUserType('guest');
            setPurchases([]);
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, userType, purchases, loading, login, signup, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
