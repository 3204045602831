import React from 'react';
import '../styles/sections/faqSection.css'

const faqData = [
    {
        id: 1,
        question: "Will I be able to buy fresh truffles?",
        answer: "Yes, we are have a large variety of British and European truffle available to purchase, depending on seasonal availability."
    },
    {
        id: 2,
        question: "Is parking available?",
        answer: "Yes, parking is available on site. Please purchase an all-day parking ticket for £4 online in advance."
    },
    {
        id: 3,
        question: "Is accessible parking available?",
        answer: "Yes, there are two designated accessible (blue badge) parking spaces at Plumpton Racecourse. For more information, please visit their website."
    },
    {
        id: 4,
        question: "Can I show up and buy tickets on the day?",
        answer: "Of course, as long as there’s space left - you’re welcome!"
    },
    {
        id: 5,
        question: "Can I bring my dog?",
        answer: "Absolutely! We welcome your furry friends. Before bringing your dog, please assess their comfort with crowds as our event can get bustling with people and other dogs. For everyone’s safety and enjoyment, keep your dog on a short, non-retractable lead at all times and ensure they're comfortable in lively environments. Fresh water will be provided, but we ask you to bring your own waste bags and clean up after your pet. When buying tickets, let us know how many dogs you’re bringing. For the well-being of all our guests, please familiarize yourself with our Animal Welfare Guidelines and check out the full event and ticket T&Cs for more details."
    },
    {
        id: 6,
        question: "Can my dog take part in the Truffle Hound Competition?",
        answer: "If you would like to enter your dog into The Truffle Hound Competition, please purchase a competitor ticket. This will allow entry for you and your dog into the festival and entry into the competition at whichever level you feel comfortable in. Competitor tickets include entry for one dog and owner."
    },
    {
        id: 7,
        question: "Can I watch the Truffle Hound Competition?",
        answer: "Yes, you will be able to watch the competition from the designated viewing area on the racecourse, or from the Lewes Grand Stand where we would recommend bringing binoculars if you have some."
    },
    {
        id: 8,
        question: "Do you have a vet on Call?",
        answer: "You will find the contact details for nearby emergency vets at the ticket office. If whilst at the event, you become concerned for a dog's welfare, please contact a member of the events team."
    },
    {
        id: 10,
        question: "Will there be toilets on site?",
        answer: "Yes, as well as an accessible toilet."
    },
    {
        id: 11,
        question: "Do I need to bring printed tickets to the event?",
        answer: "Tickets will be scanned at the entrance to the festival. You can either bring printed tickets or show the e-ticket on your phone."
    },
    {
        id: 12,
        question: "Do you have a concession for carers?",
        answer: "Yes, we offer free entry for carers. You will need to book tickets for everyone else in your group but the carers will receive complimentary entry. Please email info@greatbritishfestival.com with your booking number along with one of the following documentation – a photocopy of blue badge, PIP or DLA letter. Our event team will then issue a carer ticket to the original bookers email free of charge."
    },
    {
        id: 13,
        question: "I'm having problems booking a ticket online, what should I do?",
        answer: "We recommend using a different browser or device and if the problem persists, please email our team at info@greatbritishfestival.com and we will be happy to help."
    },
    {
        id: 14,
        question: "I can no longer attend - can I get a refund?",
        answer: "There is a 14-day grace period from the date of ticket purchase where you will receive a full refund, unless your booking is made within 30 days of the festival date. If you are unable to attend, you can re-sell your tickets through Tixel, the preferred and only resale platform of TicketTailor."
    },
    {
        id: 15,
        question: "I can no longer attend - can I transfer my ticket to a friend?",
        answer: "Yes, as long as the attendee has your booking confirmation with the original tickets."
    },
    {
        id: 16,
        question: "Can I leave and re-enter the festival on the day?",
        answer: "Yes, security will issue a re-entry wristband on your way out. Please ensure you collect one as you will not be able to get back in without this."
    },
    {
        id: 17,
        question: "Can I buy products for my dog at the festival?",
        answer: "Yes, please visit the Partners & Traders section, info will be available soon with regular updates on who will be joining us on the day."
    },
    {
        id: 18,
        question: "Will there be food and drink available?",
        answer: "Yes, we'll have a number of vendors selling hot and cold drinks and a variety of food. Please visit the Partners & Traders section; info will be available soon with regular updates on who will be joining us on the day."
    },
    {
        id: 19,
        question: "Will there be a cash machine on site?",
        answer: "No, however you will be able to pay with either cash or card at most of the traders on-site."
    },
    {
        id: 20,
        question: "Will there be first aiders on site in case anyone gets bitten?",
        answer: "Yes there will be a first aider on site or a member of staff with St Johns Ambulance certificate; however we manage and minimise the risk of this eventuality through guidelines and rules."
    },
    {
        id: 21,
        question: "What is the nearest Public Transport?",
        answer: "Plumpton train station, which takes you directly to the venue!"
    }
];

const FaqSection = () => {
    return (
        <section id="faq" className="py-5">
            <div className="container">
                <h1 className="text-center mb-4">Frequently Asked Questions</h1>
                <div className="accordion" id="faqAccordion">
                    {faqData.map((faq, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${index}`}
                                >
                                    {faq.question}
                                </button>
                            </h2>
                            <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#faqAccordion"
                            >
                                <div className="accordion-body">
                                    {faq.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
