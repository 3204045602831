import React from 'react';
import HeroSection from '../sections/HeroSection';
import CtaSection from '../sections/CtaSection';
import '../styles/pages/competitionRules.css'; 

import banner1 from '../assets/images/rulesBanners/1.png';
import banner2 from '../assets/images/rulesBanners/2.png';
import banner3 from '../assets/images/rulesBanners/3.png';
import banner4 from '../assets/images/rulesBanners/4.png';
import banner5 from '../assets/images/rulesBanners/5.png';
import banner6 from '../assets/images/rulesBanners/6.png';

const CompetitionRules = () => {
    const handleSubscribeClick = () => {
      const subscribeModal = new window.bootstrap.Modal(document.getElementById('subscribeModal'));
      subscribeModal.show();
    };
    
    return (
        <div className="competition-rules-page">
            <HeroSection 
                title="The Great British Truffle Hound Competition Rules"
                description=""
                showButton={true}
                buttonText="Download the PDF"
                buttonTarget="https://aw.thegreatbritishtrufflefestival.co.uk/v1/storage/buckets/6682bad8000f921592bc/files/66a7b923000afa3651f2/view?project=6681b8b70006e5f43807"
                isHalfHeight={true}
            />
            
            <div className="container py-5">
                <h1 className="text-center mb-4">The Great British Truffle Hound Competition 2024</h1>
                
                <div className="rules-content-wrapper">
                    <div className="rules-text-content">
                        <p>There are three competitor groups this year; Bronze, Silver and Gold, with 15 entrants per group.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner1} alt="Banner 1" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p>We have also added an under 17’s category which will be held after the adults in the same arenas open to Gold, Silver and Bronze or we will have a separate arena, dependent on numbers competing. We need to encourage the next generation to unearth the magic and carry on the knowledge.</p>
                        <p>All of you will be placed into your group based on you and your dog's experience level, which we will ask you about ahead of time via an online questionnaire form.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner2} alt="Banner 2" className="rules-image" />
                    </div>
                    
                    <div className="rules-text-content">
                        <p>Your preferred group choice will be taken into consideration, however, our head judge will make the final decision, to make sure you and everybody else are placed as fairly as possible.</p>
                        <p>You will be allocated a specific run time before the event. This will be decided at random. Hides will be refreshed periodically throughout the day.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner3} alt="Banner 3" className="rules-image" />
                    </div>
                    
                    <div className="rules-text-content">
                        <p>Competitors must not enter the competition arena before their run. There will be a waiting area by the Lewes Grandstand where competitors should be at least 10 minutes before their run time. One of the volunteer judges assistants will call you when it's your turn and direct you to the appropriate arena.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Overview</h2>
                        <p><strong>Adults</strong></p>
                        <p><strong>Gold</strong><br />- Advanced to Pro -<br />Baits: undisclosed<br />Flags: 15<br />Search Time: 10 mins<br />Bait Depth: All baits will be buried<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner4} alt="Banner 4" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p><strong>Silver</strong><br />- Intermediate to Advanced -<br />Baits: 10<br />Flags: 10<br />Search Time: 10 mins<br />Bait Depth: A mixture of above-ground and buried baits<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                        <p><strong>Bronze</strong><br />- Beginners to Intermediate -<br />Baits: 5<br />Flags: 5<br />Search Time: 5 mins to search<br />Bait Depth: All above-ground baits<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner5} alt="Banner 5" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p>Multiple entries allowed in varying categories at full price per level, but you are only permitted to enter the higher category than the previous winning category.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Groups</h2>
                        <p><strong>Adults</strong></p>
                        <p>1. Handlers receive flags (5 for Bronze, 10 for Silver, 15 for Gold).</p>
                        <p>2. When the dog indicates, the handler is to place a flag at the point of indication.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner6} alt="Banner 6" className="rules-image" />
                    </div>
                    
                    <div className="rules-text-content">
                        <p>3. If the indication is particularly active (e.g., full digging rather than just scratching), the handler is to end the indication or call the dog back.</p>
                        <p>4. A maximum of 2 digs is permitted before you'll be disqualified:<br />1st dig: verbal warning<br />2nd dig: penalty of minus 5 points<br />3rd dig: disqualification</p>
                        
                        <p>5. Toileting in the area will also result in penalties:<br />1st instance: verbal warning<br />2nd instance: penalty of minus 5 points<br />3rd instance: disqualification</p>
                        
                        <p>6. Buried baits are up to 2 inches deep.</p>
                        <p>7. Your run ends upon placing your last flag, at 10 minutes for Gold and Silver and at 5 minutes for Bronze, or when the handler retires.</p>
                        
                        <p>8. The location of flags is recorded by the judge and then scored appropriately. Handlers will not be told if the location of the flags is correct or not until the end of the competition, where you may request a copy of your scoresheet.</p>
                        
                        <p>9. The area will have a coordinate system of markers around the edge to assist in confirming the location of baits and determining the accuracy of your flag placement. (Think of the game ‘Battleship’). Each square = 2x2m. Real truffles will be in a ‘shell’ that will allow odor to escape but also protect the truffle.</p>
                        
                        <p>10. Distractions such as wildlife droppings, various obstacles, other dogs' odor, horse or human odor, etc. will naturally be included in the search area. The arena will be reset in between each run and baits refreshed periodically throughout the day.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Points Awarded</h2>
                        <p>5 points for a correct truffle oil find<br />10 points for a correct real truffle find<br />1 point for each full minute remaining on the clock</p>

                        <h2>Points Deducted</h2>
                        <p>Minus 5 points for incorrect flag placement<br />Minus 5 points for a 2nd dig<br />Minus 5 points for a 2nd toileting in the search area<br />Minus 5 points for a bad behavior warning<br />Minus 5 points for eating or removing bait once</p>

                        <h2>Disqualification</h2>
                        <p>Outside of the ring<br />Handler inappropriate handling<br />3rd dig<br />3rd toileting<br />Eating or removing bait more than once</p>

                        <h2>Plus Time</h2>
                        <p>In the event of a tie on points:<br />Step 1: Dog with least minus points<br />Step 2: Fastest dog<br />Step 3: Placings will be tied</p>
                        <p>You may request a copy of your completed and final score sheet once all competitions have ended.</p>

                        <h2>Important Information</h2>
                        <p>Everyone will be notified of their category and run time via email two weeks before the event. If we have still not received your completed registration form by this date, we cannot guarantee entry into your preferred category.</p>
                        
                        <p>If you intend on using the whistle command with your dog, please let us know by emailing us at info@thegreatbritishtrufflefestival.co.uk.</p>
                        <p>Please make sure you arrive at the festival in plenty of time.</p>
                        <p>Once you arrive, you will need to check-in at the competitor check-in point and collect your lanyard.</p>
                        
                        <p>Please be at the check-in point waiting area 10 minutes before your run time.</p>
                        <p>Please make sure your dog has toileted prior to your run. Toileting in the arena will incur penalization [please see the rules]. The reason for this is to avoid contamination of the baits and therefore ensure a fair and consistent environment for all competitors.</p>
                        
                        <p>Dogs may wear belly bands, the penalty rules will still apply should the dog attempt to toilet in the arena.</p>
                        <p>If you miss your run time, we will do our best to slot you in at a later time, however we cannot guarantee this.</p>
                        <p>Please make sure your dogs are fit and well to participate.</p>
                        
                        <p>Bitches in season can compete this year but they will go last in the varying categories.*</p>
                        <p>Please make sure your dogs are vaccinated to compete.*</p>
                        <p>No dogs to compete under 5 months old.</p>
                        
                        <p>Compulsory Conservation Awareness Multiple Questionnaire to be filled before going into the ring for Adults and Juniors.*</p>
                        <p><em>* Represents changes or new rules added for 2024 to hopefully make the competition a fair and balanced event for all the competitors</em></p>
                    </div>
                </div>
            </div>
          <CtaSection 
            id="ctaJuniors"
            title="Are you under 18 and think you've got what it takes?"
            description="This year we'll also be running a Juniors competition for age ranges 7-17, check out the Juniors rules here"
            linkText="Juniors Rules"
            linkUrl="/junior-competition-rules"
            onClick={handleSubscribeClick}
          />
        </div>
    );
};

export default CompetitionRules;
