//  src/data/sponsorsData.js


import truffleHunterLogo from '../assets/images/truffleHunter.jpg';
import paulThomasImage from '../assets/images/paulThomasImage.jpg';

export const sponsorsData = [
    {
        name: 'Truffle Hunter',
        role: 'Sponsors',
        description: 'Premier supplier of truffles and gourmet truffle-based products.',
        bio: `We’re the UK’s Leading Supplier of Fresh Truffles and Truffle Products

        We love sourcing the best seasonal fresh truffles, for customers who are as passionate about flavour as we are. Over 10 years ago, we started hunting with truffle experts in the Sibillini Mountains in central Italy, where some of the world’s finest truffles are found. We loved it so much that we’re still going back. Today, we continue to work with expert truffle hunters who source the highest quality truffles from the best truffle regions throughout Europe.

        Over the past decade we have developed a luxurious collection of truffle products for gourmet food lovers worldwide. This consists of Truffle Oils & vinegar, Truffle Dairy, Preserved Truffle, Truffle Snacks, Truffle Pasta, Truffle Condiments, and a range of luxurious gift sets.

        We are proud to be found in retail and food service sectors in over 30 countries worldwide, including the UK, Europe, USA, Canada, Asia, and the Middle East.`,
        images: [
            truffleHunterLogo,
        ],
        links: [
            {
                image: truffleHunterLogo,
                title: 'Visit Truffle Hunter Website',
                source: 'https://www.trufflehunter.co.uk/',
                sourceName: 'Truffle Hunter Website',
            },
            {
                image: truffleHunterLogo,
                title: 'Truffle Hunter on Facebook',
                source: 'https://www.facebook.com/TruffleHunterUK',
                sourceName: 'Facebook - Truffle Hunter',
            },
            {
                image: truffleHunterLogo,
                title: 'Truffle Hunter on X',
                source: 'https://x.com/TruffleHunterUK',
                sourceName: 'X - Truffle Hunter',
            },
            {
                image: truffleHunterLogo,
                title: 'Truffle Hunter on Instagram',
                source: 'https://www.instagram.com/trufflehunteruk/',
                sourceName: 'Instagram - Truffle Hunter',
            },
            {
                image: truffleHunterLogo,
                title: 'Truffle Hunter on Pinterest',
                source: 'https://www.pinterest.co.uk/trufflehunteruk/',
                sourceName: 'Pinterest - Truffle Hunter',
            },
            {
                image: truffleHunterLogo,
                title: 'Truffle Hunter on LinkedIn',
                source: 'https://www.linkedin.com/company/trufflehunter',
                sourceName: 'LinkedIn - Truffle Hunter',
            }
        ]
    },
    {
        name: 'Professor Paul Thomas, Mycorrhizal Systems Ltd',
        role: 'Sponsors',
        description: 'Leading Expert in Truffle Cultivation and Plantation Establishment',
        bio: `Our approach to truffle cultivation is different. We want you to be successful and we want to be part of your journey. We partner with landowners and we guide you at every step of the way, from orchard establishment to harvesting and distribution of the truffles.

        Having built the world's largest truffle research network and having been the first to produce truffles in a number of countries, we believe our technique is one of the most advanced currently employed. From our own genetics lab (DNA testing and research) to our own truffle distribution network, you can be comfortable that you're in the hands of experts. Established in 2005, you are welcome to visit our research team at The National Truffle Center led by global truffle expert Prof. P Thomas.

        If you're a landowner interested in Farm Diversification, and curious to learn more about truffle cultivation, then please see our information on truffle trees and the consultancy we can offer.`,
        images: [
            paulThomasImage,
            'https://plantationsystems.com/wp-content/uploads/2015/06/banner2-copy.jpg'
        ],
        links: [
            {
                image: paulThomasImage,
                title: 'Contact Mycorrhizal Systems Ltd',
                source: 'https://plantationsystems.com/contact-us/',
                sourceName: 'Mycorrhizal Systems Ltd Website',
            },
            {
                image: paulThomasImage,
                title: 'Professor Paul Thomas on X',
                source: 'https://x.com/SummerTruffle',
                sourceName: 'X - Summer Truffle',
            },
            {
                image: paulThomasImage,
                title: 'Visit the National Truffle Center',
                source: 'https://www.thenationaltrufflecenter.com/',
                sourceName: 'The National Truffle Center',
            },
            {
                image: paulThomasImage,
                title: 'Mycorrhizal Systems Ltd on LinkedIn',
                source: 'https://www.linkedin.com/company/mycorrhizal-systems-ltd/',
                sourceName: 'LinkedIn - Mycorrhizal Systems Ltd',
            },
            {
                image: paulThomasImage,
                title: 'National Truffle Center on Instagram',
                source: 'https://www.instagram.com/thenationaltrufflecentre/',
                sourceName: 'Instagram - The National Truffle Center',
            }
        ]
    }
    // Add more sponsors and partners as needed
];
