import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const ProductContext = createContext();
export const useProductContext = () => useContext(ProductContext);

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [meatProduct, setMeatProduct] = useState(null);
  const [vegProduct, setVegProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      console.log("fetching products");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
        const productsData = response.data;

        // Process products to attach prices and ticketType
        const productsWithDetails = await Promise.all(
          productsData.map(async (product) => {
            try {
              const priceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/prices/${product.default_price}`);
              
              // Determine ticketType based on metadata
              let ticketType;
              if (product.metadata.Stands === "true") {
                ticketType = "Stands";
              } else if (product.metadata.Competitors === "true") {
                ticketType = "Competitors";
              } else {
                ticketType = "Guest";
              }
              
              return {
                ...product,
                price: priceResponse.data.unit_amount / 100,
                ticketType: ticketType
              };
            } catch (priceError) {
              console.error('Error fetching price:', priceError);
              return product; 
            }
          })
        );
        console.log("productsWithDetails = ", productsWithDetails)

        // Set the full product list
        setProducts(productsWithDetails);

        // Identify and set specific products
        const meatProduct = productsWithDetails.find(product => product.id === "prod_QKsqxXa7gWT7DN");
        const vegProduct = productsWithDetails.find(product => product.id === "prod_QcWeEU7IdlhwIX");

        setMeatProduct(meatProduct);
        setVegProduct(vegProduct);

        console.log("Meat product:", meatProduct);
        console.log("Vegetarian product:", vegProduct);

      } catch (error) {
        console.error('Failed to fetch products:', error);
        setError('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products, meatProduct, vegProduct, loading, error }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
